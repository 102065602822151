<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Партнёры

        vuecrud(selectedtable = "partners", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")
          template(v-slot:popupHeader="binds")
            div.datatable-modal-container(style="background-color:#3c4b64; color: white; padding: 16px")
              h5.modal-title ДОБАВЛЕНИЕ ПАРТНЕРА
          //template(v-slot:Popup="popupInfo")

</template>

<script>

export default {
  name: 'Users',
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      users: [],
      opts: {
        canAdd: true,
        canEdit: true,
        canDelete: true
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.set('id', {label: "ID", hasFilter: true, type: 'fixed', isShowOnPopup: false, isEdit: false });
      cols.set('name', {label: "Наименование партнера", editName: "Наименование партнера", editDesc: "Наименование партнера",hasFilter: true, type: 'fixed', isShowOnPopup: true, isEdit: true });
      cols.set('desc', {label: "Код города", editName: "Код города", editDesc: "Код города",hasFilter: true, type: 'fixed', isShowOnPopup: true, isEdit: true });
      cols.set('regdir', {label: "Региональный директор", hasFilter: true, type: 'text', isShowOnPopup: true, isEdit: true, isLoadKeys: true, selectRepresentAs: (column) => '# ' + column.id + ' ' + column.name, representAs: (column) => column.name});
      cols.set('type', {label: "Франшиза", editName: "Франшиза", editDesc: "Франшиза", hasFilter: true, type: 'text', isShowOnPopup: true, isEdit: true, isLoadKeys: true, selectRepresentAs: (column) => '# ' + column.id + ' ' + column.name, representAs: (column) => column.name});
      return cols;

    },

    gotoItem(row) {
      this.$router.push({name: 'Good', params:{id: row.id}});
    }

  },

}
</script>
